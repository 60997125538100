@media print {
    .not-printable {
        display: none !important;
    }

    div {
        page-break-inside: avoid;
    }
    
    .guide-page {
        .header, .sidebar, .footer, .nav {
            display: none !important;
        }
    
        .page {
            box-shadow: none !important;
        }
    
        .wrapper {
            grid-template-columns: 1fr !important;
            padding: 0 !important;
            margin: 0 !important;
        }
    
        & {
            background: white !important;
        }
    }
}