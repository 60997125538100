.notification-container {
    position: fixed;
    right: 25px;
    width: 360px;
    bottom: 20px;
    z-index: 999999;

    .notification {
        position: relative;
        cursor: pointer;
        padding: 12px 20px 16px;
        transition: 0.3s ease;
        border-radius: 10px;
        font-size: 1em;
        margin-top: 15px;
        overflow: hidden;

        ::after {
            content: '';
            top: 0px;
            left: 0px;
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--light-gray);
            z-index: -1;
        }

        &.notification-error {
            color: #fff;
            ::after {
                background: linear-gradient(
                    to bottom right,
                    rgb(255 77 77 / 72%),
                    rgb(200 33 33 / 69%)
                );
            }
        }

        &.notification-success {
            color: #fff;
            ::after {
                background: linear-gradient(to bottom right, #41e3b6ab, #5390d9c4);
                filter: brightness(0.85);
            }
        }

        &.notification-info {
            border: 1px solid rgba(0, 0, 0, 0.05);
        }

        .title {
            font-size: 1.1em;
            margin: 4px 0px 12px;
            font-family: var(--font-700);
        }

        .message {
            word-break: break-all;
        }
    }

    .notification-enter {
        visibility: hidden;
        transform: translate3d(100%, 0, 0);
    }

    .notification-enter.notification-enter-active {
        visibility: visible;
        transform: translate3d(0, 0, 0);
        transition: all 0.4s;
    }

    .notification-leave {
        visibility: visible;
        transform: translate3d(0, 0, 0);
    }

    .notification-leave.notification-leave-active {
        visibility: hidden;
        transform: translate3d(100%, 0, 0);
        transition: all 0.4s;
    }
}
