:root {
    /* Fonts */
    --font-300: 'font300', 'Arial';
    --font-500: 'font500', 'Arial';
    --font-700: 'font700', 'Arial';
    --font-900: 'font900', 'Arial';

    /* Colors */
    --primary-blue: #1357d2;
    --dark-blue: #104dbd;
    --primary-red: #ff4c4c;
    --light-blue: #92a4e9;
    --light-grey: rgb(221, 221, 221);
    --dark-grey: #666;
    --dark: #222;
    --green: green;
    --easy-question: #1ba94c;
    --medium-question: #db7100;
    --hard-question: #db2644;

    --primary-gradient: linear-gradient(0.25turn, #1560ebf0, #1357d2f0);
    --dark-blue-gradient: linear-gradient(0.25turn, #0550db, #0346c2);

    --white: #fff;
    --light-gray: #fbfbfb;
    --medium-gray: #ddd;
    --link-color: #1357d2;
    --border-color: #f0f0f0;
    --background-page: #f5f5f5;
    --code-box-background: #f2f2f2;
    --secondary-background: #f0f0f0;

    --text-color-primary: #333;
    --text-color-secondary: #757575;
    --text-color-tertiary: #aaaaaa;

    --premium-main-color: rgb(255, 152, 0);

    --database-diagram-background: #f1f6f8;

    /* Indents */
    --indent-xs: 4px;
    --indent-s: 6px;
    --indent-m: 12px;
    --indent-l: 16px;
    --indent-xl: 20px;
    --indent-xxl: 24px;
    --indent-xxxl: 30px;

    /* Shadows */
    --shadow-xl: 0 4px 20px rgb(33 33 33 / 2%);
    --shadow-l: 0 8px 14px rgba(33, 33, 33, 0.1);
    --shadow-m: 0 4px 8px rgba(33, 33, 33, 0.15);
    --shadow-s: 0px 4px 4px 0 rgba(33, 33, 33, 0.1);
    --shadow-xs: 0 2px 2px 0 rgba(33, 33, 33, 0.1);

    --header-height: 65px;

    --border-radius-s: 6px;
    --border-radius-m: 10px;
    --border-radius-l: 16px;
    --border-radius-xl: 20px;
    --border-radius-xxl: 24px;

    --page-width-s: 770px;
    --page-width-m: 960px;
    --page-width-l: 1380px;
}

body {
    box-sizing: border-box;
    margin: 0px;
    color: var(--text-color-primary);
    font-size: 16px;
    font-family: var(--font-500);
}

.disable-scroll {
    // Лютый хак, чтобы работало на iOS
    height: 1%;

    overflow: hidden;
    box-sizing: border-box;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

a {
    color: var(--link-color);
}

a:has(.sql-code-line) {
    padding-bottom: 1px;
}

.ReactModal__Overlay {
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.ace_autocomplete {
    position: fixed !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
    border: none !important;
}

.ace_doc-tooltip {
    white-space: normal;
    padding: 0;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    background: white;
    padding: 6px;
    max-width: 240px;

    .description {
        margin-top: 7px;
        color: #555;
        font-size: 0.95em;
    }

    .params {
        .title {
            margin-top: 10px;
            font-family: 'font700', 'Arial';
        }

        .params-item {
            margin-top: 7px;

            .label {
                background: #eeefef;
                padding: 1px 5px;
                border-radius: 4px;
            }
        }
    }
}

.hidden {
    position: absolute;
    pointer-events: none;
    height: 0;
    width: 0;
    overflow: hidden;
}

.__react_component_tooltip.global {
    border-radius: 10px;
    font-size: 1rem;

    max-width: 250px;
    word-wrap: break-word;
    padding: 8px 12px;

    box-shadow: var(--shadow-m);
    z-index: 9999;

    .multi-line {
        text-align: left;
    }

    &::before {
        display: none;
    }
}

strong {
    font-family: var(--font-700);
}

@font-face {
    font-family: 'font300';
    font-display: swap;
    font-weight: 300;

    src: url('/static/fonts/MuseoSansCyrl-300.woff2');
}

@font-face {
    font-family: 'font500';
    font-display: swap;
    font-weight: 500;

    src: url('/static/fonts/MuseoSansCyrl-500.woff2');
}

@font-face {
    font-family: 'font700';
    font-display: swap;
    font-weight: 700;

    src: url('/static/fonts/MuseoSansCyrl-700.woff2');
}

@font-face {
    font-family: 'font900';
    font-display: swap;
    font-weight: 900;

    src: url('/static/fonts/MuseoSansCyrl-900.woff2');
}
